// import'bootstrap/dist/js/bootstrap.bundle';
// import 'tether';
// import 'picturefill';
// import 'lazysizes/plugins/parent-fit/ls.parent-fit';
// import 'lazysizes/plugins/bgset/ls.bgset';
// import 'lazySizes';
import Sortable from 'sortablejs';
import 'formbuilder';
// import 'parsleyjs';

// import Modernizr from './lib/modernizr';
// import {Detectizr} from 'detectizr';

// import'bootstrap/dist/js/bootstrap.bundle';
// window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js')

// import $ from './lib/jquery';

// import 'slick-carousel';
//import  Modernizr from 'modernizr/bin/modernizr';
//  var Modernizr = require('./modernizr.js');
// import {Modernizr} from './modernizr.js';
// import './lib/detectizr.min.js';
//var Detectizr = require('./detectizr.js');
//import {Detectizr} from './detectizr.min';

// import "magnific-popup";
// import 'magnific-popup/dist/jquery.magnific-popup';

//import gsap from 'gsap';


// import 'masonry-layout';

/**
 * JS-Funktionen fuer seitenblick.de
 *
 * Seitenblick Digitale Medien GmbH
 * e-Mail : info@seitenblick.de
 *
 * Version: 1.0
 */


/**
 * Name           : isMobile
 * Beschreibung   : Mobilgerät-Erkennung
 * Parameter      : -
 * Rückgabe       : -
 * Bibliothek     : https://github.com/kaimallea/isMobile
 */
!function (a) {
    var b = /iPhone/i, c = /iPod/i, d = /iPad/i, e = /(?=.*\bAndroid\b)(?=.*\bMobile\b)/i, f = /Android/i,
        g = /(?=.*\bAndroid\b)(?=.*\bSD4930UR\b)/i,
        h = /(?=.*\bAndroid\b)(?=.*\b(?:KFOT|KFTT|KFJWI|KFJWA|KFSOWI|KFTHWI|KFTHWA|KFAPWI|KFAPWA|KFARWI|KFASWI|KFSAWI|KFSAWA)\b)/i,
        i = /IEMobile/i, j = /(?=.*\bWindows\b)(?=.*\bARM\b)/i, k = /BlackBerry/i, l = /BB10/i, m = /Opera Mini/i,
        n = /(CriOS|Chrome)(?=.*\bMobile\b)/i, o = /(?=.*\bFirefox\b)(?=.*\bMobile\b)/i,
        p = new RegExp("(?:Nexus 7|BNTV250|Kindle Fire|Silk|GT-P1000)", "i"), q = function (a, b) {
            return a.test(b)
        }, r = function (a) {
            var r = a || navigator.userAgent, s = r.split("[FBAN");
            return "undefined" != typeof s[1] && (r = s[0]), this.apple = {
                phone: q(b, r),
                ipod: q(c, r),
                tablet: !q(b, r) && q(d, r),
                device: q(b, r) || q(c, r) || q(d, r)
            }, this.amazon = {
                phone: q(g, r),
                tablet: !q(g, r) && q(h, r),
                device: q(g, r) || q(h, r)
            }, this.android = {
                phone: q(g, r) || q(e, r),
                tablet: !q(g, r) && !q(e, r) && (q(h, r) || q(f, r)),
                device: q(g, r) || q(h, r) || q(e, r) || q(f, r)
            }, this.windows = {
                phone: q(i, r),
                tablet: q(j, r),
                device: q(i, r) || q(j, r)
            }, this.other = {
                blackberry: q(k, r),
                blackberry10: q(l, r),
                opera: q(m, r),
                firefox: q(o, r),
                chrome: q(n, r),
                device: q(k, r) || q(l, r) || q(m, r) || q(o, r) || q(n, r)
            }, this.seven_inch = q(p, r), this.any = this.apple.device || this.android.device || this.windows.device || this.other.device || this.seven_inch, this.phone = this.apple.phone || this.android.phone || this.windows.phone, this.tablet = this.apple.tablet || this.android.tablet || this.windows.tablet, "undefined" == typeof window ? this : void 0
        }, s = function () {
            var a = new r;
            return a.Class = r, a
        };
    "undefined" != typeof module && module.exports && "undefined" == typeof window ? module.exports = r : "undefined" != typeof module && module.exports && "undefined" != typeof window ? module.exports = s() : "function" == typeof define && define.amd ? define("isMobile", [], a.isMobile = s()) : a.isMobile = s()
}(this);


/**
 * Name           : IE HTML5 Fix
 * Beschreibung   : Bringt dem IE < 9 HTML5-Elemente bei
 * Parameter      : -
 * Rückgabe       : -
 * Bibliothek     : Nativ
 */
"'article aside footer header nav section time'".replace(/\w+/g, function (n) {
    document.createElement(n)
})


/**
 * Name           : Picture element HTML5 shiv
 * Beschreibung   : HTML5 Elemente für IE8
 * Parameter      : -
 * Rückgabe       : -
 * Bibliothek     : Nativ
 */
document.createElement("picture");


$(document).ready(function () {

    /**
     * Name           :
     * Beschreibung   : Funktionen die bei Resize ausgeführt werden müssen
     * Parameter      : -
     * Rückgabe       : -
     * Bibliothek     : jQuery
     */
    $(window).resize(function () {

    });


    /**
     * jQuery Plugin to obtain touch gestures from iPhone, iPod Touch and iPad, should also work with Android mobile phones (not tested yet!)
     * Common usage: wipe images (left and right to show the previous or next image)
     *
     * @author Andreas Waltl, netCU Internetagentur (http://www.netcu.de)
     * @version 1.1.1 (9th December 2010) - fix bug (older IE's had problems)
     * @version 1.1 (1st September 2010) - support wipe up and wipe down
     * @version 1.0 (15th July 2010)
     */
    (function ($) {
        $.fn.touchwipe = function (settings) {
            var config = {
                min_move_x: 20, min_move_y: 20, wipeLeft: function () {
                }, wipeRight: function () {
                }, wipeUp: function () {
                }, wipeDown: function () {
                }, preventDefaultEvents: true
            };
            if (settings) $.extend(config, settings);
            this.each(function () {
                var startX;
                var startY;
                var isMoving = false;

                function cancelTouch() {
                    this.removeEventListener('touchmove', onTouchMove);
                    startX = null;
                    isMoving = false
                }

                function onTouchMove(e) {
                    if (config.preventDefaultEvents) {
                        e.preventDefault()
                    }
                    if (isMoving) {
                        var x = e.touches[0].pageX;
                        var y = e.touches[0].pageY;
                        var dx = startX - x;
                        var dy = startY - y;
                        if (Math.abs(dx) >= config.min_move_x) {
                            cancelTouch();
                            if (dx > 0) {
                                config.wipeLeft()
                            } else {
                                config.wipeRight()
                            }
                        } else if (Math.abs(dy) >= config.min_move_y) {
                            cancelTouch();
                            if (dy > 0) {
                                config.wipeDown()
                            } else {
                                config.wipeUp()
                            }
                        }
                    }
                }

                function onTouchStart(e) {
                    if (e.touches.length == 1) {
                        startX = e.touches[0].pageX;
                        startY = e.touches[0].pageY;
                        isMoving = true;
                        this.addEventListener('touchmove', onTouchMove, false)
                    }
                }

                if ('ontouchstart' in document.documentElement) {
                    this.addEventListener('touchstart', onTouchStart, false)
                }
            });
            return this
        }
    })(jQuery);

    /**
     * Name           :
     * Beschreibung   : Startseite Animation
     * Parameter      : -
     * Rückgabe       : -
     * Bibliothek     : jQuery
     */
    //Detectizr initialisieren
    Detectizr.detect({detectScreen: false});

    //Variablen
    var app = app || {},
        slider = slider || {},
        $document = $(document),
        $window = $(window),
        $htmlBody = $('html, body'),
        $html = $('html'),
        $body = $('body'),
        transitionEnd = 'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
        desktop = Detectizr.device.type == 'desktop',
        $main = $('.main_content'),
        t = 0,
        $title = $('.project-content'),
        $btnlink = $('.project-content .project-link'),
        $navbullets = $('.nav-link'),
        $menuIcon = $('#nav-icon span'),
        $footerLinks = $('.footerlinks a'),
        $imageCopyright = $('.project-image-copyright'),
        $logopath = $('.logo path'),
        $logopolygon = $('.logo polygon'),
        $btnnextpolygon = $('#btn-next polygon'),
        $logorect = $('.logo rect'),
        $btnNext = $('#btn-next'),
        $navLinks = $('.nav-item'),
        $projects = $('.project'),
        $pTitles = $('.project-title'),
        $contents = $('.project-content'),
        $contentsLeft = $('.project-image'),
        _direction = null,
        _current = null,
        _prev = null,
        _next = null,
        _total = null,
        _lastAnim = 0,
        _delay = 100,
        _duration = 1000,
        _colors = null,
        _prevColors = null;
    TweenLite.defaultEase = Expo.easeInOut;


    //Initialisierung
    app.init = function (parent, children) {
        $('a[href="#"]').on('click', function (e) {
            e.preventDefault();
        });

        slider.init();
        $navLinks.each(function (i, e) {
            var $this = $(this);
            $this.on('click', function (e) {
                e.preventDefault();
                var dir = i > _current ? 'next' : 'prev';
                slider.slide(i, dir);
            });
        });
        $btnNext.on('click', function () {
            // console.log('btnNext clicked');
            var index = _current + 1 <= _total ? _current + 1 : 0;
            // console.log('index: ' + index);
            slider.slide(index, 'next');
        });
        $document.bind('keyup', app.keyNav);

        $document.bind('mousewheel DOMMouseScroll', app.scrollNav).touchwipe({
            wipeDown: app.touchNavDown,
            wipeUp: app.touchNavUp
        });
    };

    /*Navigation mit Arrow-Keys*/
    app.keyNav = function (event) {
        if (event.which === 40 || event.which === 39) {
            //console.log(event.which);
            index = _current + 1 <= _total ? _current + 1 : 0;
            //console.log('dir-next-index: ' + index);
            slider.slide(index, 'next');
        }
        if (event.which === 38 || event.which === 37) {
            index = _current - 1 >= 0 ? _current - 1 : _total;
            //console.log('index: ' + index);
            slider.slide(index, 'prev');
        }
    };
    /*Navigation Mausrad*/
    app.scrollNav = function (event) {
        event.preventDefault();

        var t = event.originalEvent.wheelDelta || -event.originalEvent.detail;
        //console.log('t: ' + t);


        var n = (new Date()).getTime();
        if (n - _lastAnim < _delay + _duration) {
            event.preventDefault();
            return;
        }
        if (t < 0) {
            //console.log('goto next');
            //console.log('-------');
            //console.log('_current' + _current + '- _total' + _total);
            var i = _current + 1 <= _total ? _current + 1 : 0;
            //console.log('i: ' + i);
            //console.log('-------');
            slider.slide(i, 'next');
        } else if (t > 0) {
            //console.log('goto prev');
            var i = _current - 1 >= 0 ? _current - 1 : _total;
            slider.slide(i, 'prev');
        }
        _lastAnim = n;
        //console.log('_lastAnim: '+ n);
    };
    /*Navigation Touch-Device*/
    app.touchNavDown = function (event) {
        index = _current - 1 >= 0 ? _current - 1 : _total;
        slider.slide(index, 'next');
    };
    app.touchNavUp = function (event) {
        index = _current + 1 <= _total ? _current + 1 : 0;
        slider.slide(index, 'prev');
    };

    //Sliderinitialisierung
    slider.init = function () {
        var pathname = window.location.pathname.replace(/\//g, '');
        //console.log('pathname: ' + pathname);
        _current = pathname !== '' && pathname !== '01_startseite.php' ? $('#' + pathname).index() : 0;
        _current = _current === -1 ? 0 : _current;
        //console.log('_current: ' + _current);
        _prev = -1;
        _next = 1;
        _total = $navLinks.length - 1;
        //console.log('_total: ' + _total);
        var $link = $navLinks.eq(_current),
            $project = $projects.eq(_current),
            $content = $contents.eq(_current),
            $contentLeft = $contentsLeft.eq(_current);
        //console.log('$link: ' + $link[_current]);
        //console.log('$content: ' + $content[_current]);
        $link.data('active', true).addClass('is-active');
        $project.data('active', true).addClass('is-active');

        TweenLite.set($content, {
            y: '0%'
        });
        TweenLite.set($contentLeft, {
            y: '0%'
        });

        _prevColors = {
            bg: $project.data('bg') === undefined ? null : $project.data('bg'),
            pattern: $project.data('pattern') === undefined ? null : app.hexToRgb($project.data('pattern')),
            main: $project.data('main') === undefined ? null : app.hexToRgb($project.data('main')),
            secondary: $project.data('secondary') === undefined ? null : app.hexToRgb($project.data('secondary')),
            siteTitle: $project.data('sitetitle') === undefined ? null : $project.data('sitetitle'),
            btnLink: $project.data('btnlink') === undefined ? '#fff' : $project.data('btnlink'),
            navBullets: $project.data('navbullets') === undefined ? null : $project.data('navbullets'),
            menuIcon: $project.data('menuIcon') === undefined ? null : $project.data('menuIcon'),
            logoColor: $project.data('logo') === undefined ? null : $project.data('logo')
        };
        slider.updateColors(_prevColors, null);
    };

    /*Slider-Bewegung*/
    slider.slide = function (index, direction) {
        //console.log('index:' + index +' - direction: ' + direction );
        $document.unbind('keyup', app.keyNav);
        $document.unbind('mousewheel DOMMouseScroll', app.keyNav);
        _current = index;
        _prev = $main.find('.is-active').index();
        // console.log("_prev: " + _prev);
        // console.log("current: " + _current);
        _direction = direction;
        var $link = $navLinks.eq(_current),
            $project = $projects.eq(_current),
            $content = $contents.eq(_current),
            $contentLeft = $contentsLeft.eq(_current);
        _colors = {
            bg: $project.data('bg') === undefined ? null : $project.data('bg'),
            pattern: $project.data('pattern') === undefined ? null : app.hexToRgb($project.data('pattern')),
            main: $project.data('main') === undefined ? null : app.hexToRgb($project.data('main')),
            secondary: $project.data('secondary') === undefined ? null : app.hexToRgb($project.data('secondary')),
            siteTitle: $project.data('sitetitle') === undefined ? '#fff' : $project.data('sitetitle'),
            btnLink: $project.data('btnlink') === undefined ? '#fff' : $project.data('btnlink'),
            navBullets: $project.data('navbullets') === undefined ? '#fff' : $project.data('navbullets'),
            menuIcon: $project.data('menuIcon') === undefined ? '#fff' : $project.data('menuIcon'),
            logoColor: $project.data('logo') === undefined ? null : $project.data('logo')
        };
        slider.updateColors(_colors, _direction);

        var $prevLink = $navLinks.eq(_prev),
            $prevProject = $projects.eq(_prev),
            $prevContent = $contents.eq(_prev),
            $prevContentLeft = $contentsLeft.eq(_prev);
        if (!$link.data('active')) {
            // console.log('Link is not active');
            //history.replaceState('', 'Seitenblick Digitale Medien ' + $link.data('title'), '/' + $link.attr('href'));
            $navLinks.removeClass('is-active').data('active', false);
            $link.addClass('is-active').data('active', true);
            $projects.removeClass('is-active');
            $project.addClass('is-active');

            if ($(window).width() > 767) {
                /*Startposition des Projekttextes (rechte Seite)*/
                TweenLite.set($content, {
                    y: _direction === 'next' ? '100%' : '-100%'
                });
                /*Startposition des Folgebildes (linke Seite)*/
                TweenLite.set($contentLeft, {
                    y: _direction === 'next' ? '-100%' : '100%'
                });
                /*Position an die der vorige Projekt-Text geschoben wird (rechte Seite)*/
                TweenLite.to($prevContent, 1, {
                    y: _direction === 'next' ? '-100%' : '100%'
                });
                /*Position an die das vorige Bild geschoben wird (linke Seite)*/
                TweenLite.to($prevContentLeft, 1, {
                    y: _direction === 'next' ? '100%' : '-100%'//100% = ganz nach unten - -100% = ganz nach oben
                });

                TweenLite.to($content, 0.75, {
                    y: '0%',/*Position an die das Folgeinhalt (rechte Seite) geschoben wird*/
                    onComplete: function () {
                        _prevColors = _colors;
                        $document.bind('keyup', app.keyNav);
                        $document.bind('mousewheel DOMMouseScroll', app.keyNav);
                    }
                });
                TweenLite.to($contentLeft, 0.75, {
                    y: '0%', /*Position an die das Folgebild (linke Seite) geschoben wird*/

                });
            } else {
                /*Startposition des Projekttextes (mobil, unten)*/
                TweenLite.set($content, {
                    y: _direction === 'next' ? '200%' : '-200%'
                });
                /*Startposition des Folgebildes (mobil, oben)*/
                TweenLite.set($contentLeft, {
                    y: _direction === 'next' ? '200%' : '-200%'
                });
                /*Position an die der vorige Projekt-Text geschoben wird (mobil, unten)*/
                TweenLite.to($prevContent, 1, {
                    y: _direction === 'next' ? '-200%' : '200%'
                });
                /*Position an die das vorige Bild geschoben wird (mobil, oben)*/
                TweenLite.to($prevContentLeft, 1, {
                    y: _direction === 'next' ? '-200%' : '200%'//100% = ganz nach unten - -100% = ganz nach oben
                });

                TweenLite.to($content, 0.75, {
                    y: '0%',/*Position an die das Folgeinhalt (rechte Seite) geschoben wird*/
                    onComplete: function () {
                        _prevColors = _colors;
                        $document.bind('keyup', app.keyNav);
                        $document.bind('mousewheel DOMMouseScroll', app.keyNav);
                    }
                });
                TweenLite.to($contentLeft, 0.75, {
                    y: '0%', /*Position an die das Folgebild (linke Seite) geschoben wird*/
                    onComplete: function () {
                        $document.bind('keyup', app.keyNav);
                        $document.bind('mousewheel DOMMouseScroll', app.keyNav);
                    }
                });
            }
        }
    };
    /*Slider Farbaktualisierung*/
    slider.updateColors = function (colors, direction) {
        if (direction === null) {
            $contents.css('background', colors.bg);
            $title.css('color', colors.siteTitle);
            $btnlink.css('color', colors.bg);
            $btnlink.css('backgroundColor', colors.siteTitle);
            $navbullets.css('backgroundColor', colors.navBullets);
            $menuIcon.css('backgroundColor', colors.navBullets);
            $imageCopyright.css('color', colors.navBullets);
            $navLinks.css('color', colors.navBullets);
            $logopolygon.css('fill', colors.logoColor);
            $logorect.css('fill', colors.logoColor);
            $logopath.css('fill', colors.logoColor);
            $footerLinks.css('color', colors.logoColor);
            $btnnextpolygon.css('fill', colors.logoColor);


        } else {
            if (!Modernizr.csstransitions) {
                TweenLite.to($title, 1, {
                    color: colors.siteTitle
                });
                TweenLite.to($navbullets, 1, {
                    backgroundColor: colors.navBullets
                });
                TweenLite.to($menuIcon, 1, {
                    backgroundColor: colors.navBullets
                });
                TweenLite.to($imageCopyright, 1, {
                    color: colors.navBullets
                });
                TweenLite.to($btnlink, 1, {
                    backgroundColor: colors.siteTitle
                });
                TweenLite.to($btnlink, 1, {
                    color: colors.bg
                });
                TweenLite.to($navLinks, 1, {
                    color: colors.navBullets
                });
                TweenLite.to($logopath, 1, {
                    fill: colors.logoColor
                });
                TweenLite.to($footerLinks, 1, {
                    color: colors.logoColor
                });
                TweenLite.to($logorect, 1, {
                    fill: colors.logoColor
                });
                TweenLite.to($logopolygon, 1, {
                    fill: colors.logoColor
                });
                TweenLite.to($btnnextpolygon, 1, {
                    fill: colors.logoColor
                });
                TweenLite.to($contents, 1, {
                    backgroundColor: colors.bg
                });
            } else {
                $title.css('color', colors.siteTitle);
                $contents.css('backgroundColor', colors.bg);
                $btnlink.css('color', colors.bg);
                $btnlink.css('backgroundColor', colors.siteTitle);
                $navbullets.css('backgroundColor', colors.navBullets);
                $menuIcon.css('backgroundColor', colors.navBullets);
                $imageCopyright.css('color', colors.navBullets);
                $navLinks.css('color', colors.navBullets);
                $logopolygon.css('fill', colors.logoColor);
                $logorect.css('fill', colors.logoColor);
                $logopath.css('fill', colors.logoColor);
                $footerLinks.css('color', colors.logoColor);
                $btnnextpolygon.css('fill', colors.logoColor);
            }
        }
    };

    //Initialisierung nur auf der Startseite
    $(function () {
        if ($('.home').length > 0) {
            app.init();
        }
    });


    /**
     * Name           : Masonry,Filtered Masonry
     * Beschreibung   : -
     * Parameter      : -
     * Rückgabe       : -
     * Bibliothek     : http://masonry.desandro.com/, http://dynamick.github.io/multiple-filter-masonry/,  jQuery
     */

    /* simuliert Klick auf ALL um das Grid in der Mobilansicht zu initialisieren */
    setTimeout(function () {
        $('#reset').trigger('click');
    }, 100);

    if ($('#masonryContainer').length > 0) {
        var $container = $('#masonryContainer');
        $container.multipleFilterMasonry({
            columnWidth: 360,
            itemSelector: '.grid-item',
            filtersGroupSelector: '.filters',
            gutter: 20,
            isFitWidth: true,
            // set the selectorType to use <ul> instead of inputs
            selectorType: 'list'
        });

        $container.on('layoutComplete', function (event, laidOutItems) {
            //console.log( 'Masonry layout completed on ' +
            //laidOutItems.length + ' items' );
        });
    }


    /**
     * Name           : Fadeout
     * Beschreibung   : Blendet Hinweisboxen zeitgesteuert wieder aus
     * Parameter      : Klasse des Elements
     * Rückgabe       : -
     * Bibliothek     : jQuery
     */
    /*--- Hinweisbox beim Formulargenerator als Erfolgsmeldung - bei vorh. Klasse 'permanent' dauerhaft einblenden ---*/
    $(".label-success").not('.permanent').css("display", "none").delay(200).fadeIn().delay(5000).fadeOut();

    /*--- Hinweisbox AGB Tauschbörse ---------------------------------------------------------------*/
    $(".tos").click(function () {
        $(".termsofservice").fadeToggle("slow", "linear");
    });


    /**
     * Name           : Hamburger-Navigations-Icon
     * Beschreibung   : animiertes Icon
     * Parameter      : -
     * Rückgabe       : -
     * Bibliothek     : jQuery
     */

    $('#nav-icon').click(function () {
        $(this).toggleClass('open');
        $(this).closest('.nav-icon-wrapper').toggleClass('fixed');
        $('nav').toggleClass('active');
        /*
        TweenMax.to("nav", 1, {right: "auto", ease:Elastic.easeOut}, 0.2);
        $('#nav-icon').toggleClass('active');
        */
    });
    /*
      $('.container').on("click",'.active', function(){
      TweenMax.from("nav", 1, {right: "98%", ease:Elastic.easeOut}, 0.2);
    });
    */

    /*Animationen*/
    TweenMax.staggerFrom(".icon-competence", 2, {
        scale: 0.5,
        opacity: 0,
        delay: 0.5,
        ease: Elastic.easeOut,
        force3D: true
    }, 0.2);
    TweenMax.staggerFrom(".kompetenz h2", 0.3, {opacity: 0, x: 100, delay: 0.5}, 0.1);

    var title_color = $("article").data("sitetitle"),
        background_color = $("article").data("bg");
    $(".project-content").css("color", title_color);
    $(".project-content").css("backgroundColor", background_color);


    /**
     * Name           : Slick-Slider
     * Beschreibung   : the last carousel you'll ever need
     * Parameter      : -
     * Rückgabe       : -
     * Bibliothek     : http://kenwheeler.github.io/slick/
     */
    $('.slider').slick({
        dots: true,
    });

    $('.gallery').slick({
        centerMode: false,
        centerPadding: '60px',
        slidesToShow: 3,
        infinite: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    centerMode: false,
                    centerPadding: '40px',
                    slidesToShow: 2.5
                }
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    centerMode: false,
                    centerPadding: '40px',
                    slidesToShow: 1.25
                }
            }
        ]
    });


    /**
     * Name           : MagnificPopup
     * Beschreibung   : PopUps und Lightbox für Artikelbilder, Hinweise und Formulare
     * Parameter      : Klasse des Elements
     * Rückgabe       : -
     * Bibliothek     : jQuery, Magnific Popup (magnific_min.js)
     */
    var groups = {};
    jQuery('.mfp').each(function () {
        var id = parseInt($(this).attr('data-group'), 10);

        if (!groups[id]) {
            groups[id] = [];
        }

        groups[id].push(this);
    });

    jQuery.each(groups, function () {

        $(this).magnificPopup({
            type: 'image',
            closeOnContentClick: true,
            closeBtnInside: false,
            gallery: {
                enabled: true,
                tCounter: '<span class="mfp-counter">%curr% von %total%</span>' // markup of counter
            }
        })

    });


    /**
     * Name           : Sortable
     * Beschreibung   : Reorderable drag-and-drop lists for modern browsers and touch devices. No jQuery or framework required.
     * Parameter      : -
     * Rückgabe       : -
     * Bibliothek     : https://github.com/SortableJS/Sortable
     */
    if ($("#draggable-items").length) {
        var el = document.getElementById('draggable-items');
        var sortable = Sortable.create(el, {
            animation: 600,
            group: "localStorage-example",
            store: {
                /**
                 * Get the order of elements. Called once during initialization.
                 * @param   {Sortable}  sortable
                 * @returns {Array}
                 */
                get: function (sortable) {
                    var order = localStorage.getItem(sortable.options.group.name);
                    return order ? order.split('|') : [];
                },

                /**
                 * Save the order of elements. Called onEnd (when the item is dropped).
                 * @param {Sortable}  sortable
                 */
                set: function (sortable) {
                    var order = sortable.toArray();
                    localStorage.setItem(sortable.options.group.name, order.join('|'));
                }
            }
        })
    }



    $(document).ready(function () {
        /**
         * Name           : Formbuilder
         * Beschreibung   :  Drag and Drop - Full-featured Form Editing
         * Parameter      : -
         * Rückgabe       : -
         * Bibliothek     : https://formbuilder.online/
         */
        jQuery(function ($) {
            if ($('.formbuilder').length > 0) {

                // var fbEditor = document.getElementById('fb-editor');
                // var formBuilder = $(fbEditor).formBuilder();


                let options = {
                    enableColumnInsertMenu: false,
                    enableEnhancedBootstrapGrid: false,
                    typeUserAttrs: {
                        text: {
                            'parsley-error-message': {
                                label: 'Fehlermeldung',
                                value: 'Bitte füllen Sie dieses Feld aus.',
                            }
                        },
                    },
                    fields: [
                        {
                            label: 'Datenschutzerklärung zustimmen',
                            attrs: {
                                type: 'dsgvo'
                            },
                            icon: '🌟'
                        }
                    ],
                    templates: {
                        dsgvo: function (fieldData) {
                            return {
                                field: '<span id="' + fieldData.name + '">',
                                // onRender: function() {
                                //     $(document.getElementById(fieldData.name)).rateYo({
                                //         rating: 3.6
                                //     });
                                // }
                            };
                        }
                    },
                    i18n: {
                        locale: 'de-DE',
                        location: '../assets/lang/'
                    },
                    // i18n: {
                    //     locale: 'de-DE',
                    //     location: '../assets/lang/',
                    //     override: {
                    //         'de-DE': {
                    //             addOption: 'Add Option +',
                    //             allFieldsRemoved: 'All fields were removed.',
                    //             allowMultipleFiles: 'Allow users to upload multiple files',
                    //             autocomplete: 'Automatsche Vervollständischung',
                    //             button: 'Knopp',
                    //             cannotBeEmpty: 'This field cannot be empty',
                    //             checkboxGroup: 'Häcksche',
                    //             className: 'Class',
                    //             clearAllMessage: 'Are you sure you want to clear all fields?',
                    //             clear: 'wechmache',
                    //             close: 'Close',
                    //             content: 'Content',
                    //             copy: 'Copy To Clipboard',
                    //             copyButton: '&#43;',
                    //             copyButtonTooltip: 'Copy',
                    //             dateField: 'Daadum',
                    //             description: 'Help Text',
                    //             descriptionField: 'Description',
                    //             devMode: 'Developer Mode',
                    //             editNames: 'Edit Names',
                    //             editorTitle: 'Form Elements',
                    //             editXML: 'Edit XML',
                    //             enableOther: 'Enable &quot;Other&quot;',
                    //             enableOtherMsg: 'Let users to enter an unlisted option',
                    //             fieldNonEditable: 'This field cannot be edited.',
                    //             fieldRemoveWarning: 'Are you sure you want to remove this field?',
                    //             fileUpload: 'Dokumente raflade',
                    //             formUpdated: 'Form Updated',
                    //             getStarted: 'Schieb e Feld von rechts in den Bereich erinn.',
                    //             header: 'Iwwerschrift',
                    //             hide: 'Edit',
                    //             hidden: 'Verdeckts Feldche',
                    //             inline: 'Inline',
                    //             inlineDesc: 'Display {type} inline',
                    //             label: 'Label',
                    //             labelEmpty: 'Field Label cannot be empty',
                    //             limitRole: 'Limit access to one or more of the following roles:',
                    //             mandatory: 'Mandatory',
                    //             maxlength: 'Max Length',
                    //             minOptionMessage: 'This field requires a minimum of 2 options',
                    //             multipleFiles: 'Multiple Files',
                    //             name: 'Name',
                    //             no: 'No',
                    //             noFieldsToClear: 'There are no fields to clear',
                    //             number: 'Zahle-Feld',
                    //             off: 'Off',
                    //             on: 'On',
                    //             option: 'Option',
                    //             options: 'Options',
                    //             optional: 'optional',
                    //             optionLabelPlaceholder: 'Label',
                    //             optionValuePlaceholder: 'Value',
                    //             optionEmpty: 'Option value required',
                    //             other: 'Other',
                    //             paragraph: 'Absaad',
                    //             placeholder: 'Placeholder',
                    //             'placeholder.value': 'Value',
                    //             'placeholder.label': 'Label',
                    //             'placeholder.text': '',
                    //             'placeholder.textarea': '',
                    //             'placeholder.email': 'Enter you email',
                    //             'placeholder.placeholder': '',
                    //             'placeholder.className': 'space separated classes',
                    //             'placeholder.password': 'Enter your password',
                    //             preview: 'Preview',
                    //             radioGroup: 'Klaaner Schalter',
                    //             radio: 'Klaaner Schalter',
                    //             removeMessage: 'Remove Element',
                    //             removeOption: 'Remove Option',
                    //             remove: '&#215;',
                    //             required: 'Required',
                    //             richText: 'Rich Text Editor',
                    //             roles: 'Access',
                    //             rows: 'Rows',
                    //             save: 'abspeiche',
                    //             selectOptions: 'Options',
                    //             select: 'Auswahlkästche',
                    //             selectColor: 'Select Color',
                    //             selectionsMessage: 'Allow Multiple Selections',
                    //             size: 'Size',
                    //             'size.xs': 'Extra Small',
                    //             'size.sm': 'Small',
                    //             'size.m': 'Default',
                    //             'size.lg': 'Large',
                    //             style: 'Style',
                    //             styles: {
                    //                 btn: {
                    //                     'default': 'Default',
                    //                     danger: 'Danger',
                    //                     info: 'Info',
                    //                     primary: 'Primary',
                    //                     success: 'Success',
                    //                     warning: 'Warning'
                    //                 }
                    //             },
                    //             subtype: 'Type',
                    //             text: 'Tegschdkaschde',
                    //             textArea: 'Tegschfeld',
                    //             toggle: 'Toggle',
                    //             warning: 'Warning!',
                    //             value: 'Value',
                    //             viewJSON: '{  }',
                    //             viewXML: '&lt;/&gt;',
                    //             yes: 'Yes'
                    //         }
                    //     },
                    // },
                    dataType: 'xml',
                    onSave: function (evt, formData) {
                        showPreview(formData)
                    },
                    formData: '<form-template><fields></fields></form-template>'
                };
                const $fbEditor = $(document.getElementById('fb-editor'));

                const formBuilder = $fbEditor.formBuilder(options);

                function showPreview(formData) {
                    let formRenderOpts = {
                        dataType: 'xml',
                        formData
                    };
                    let $renderContainer = $('<form/>');
                    $renderContainer.formRender(formRenderOpts);
                    let html = `<!doctype html><title>Form Preview</title><body class="container"><h1>Preview</h1><hr><form id="testform" data-parsley-validate="">${$renderContainer.html()}<button type="submit" class="btn-default btn" name="button-1701876177747-0" style="default" id="button-1701876177747-0">Knopp</button></form></body></html>`;
                    var formPreviewWindow = window.open('', 'formPreview', 'height=480,width=640,toolbar=no,scrollbars=yes');

                    formPreviewWindow.document.write(html);
                    var style = document.createElement('link');
                    style.setAttribute('href', 'https://maxcdn.bootstrapcdn.com/bootstrap/3.3.6/css/bootstrap.min.css');
                    style.setAttribute('rel', 'stylesheet');
                    style.setAttribute('type', 'text/css');
                    formPreviewWindow.document.head.appendChild(style);
                    var parsleycss = document.createElement('link');
                    parsleycss.setAttribute('href', 'https://cdn.jsdelivr.net/npm/parsleyjs@2.9.2/src/parsley.min.css');
                    parsleycss.setAttribute('rel', 'stylesheet');
                    parsleycss.setAttribute('type', 'text/css');
                    formPreviewWindow.document.head.appendChild(parsleycss);
                    var jqueryjs = document.createElement('script');
                    jqueryjs.setAttribute('src', 'https://cdn.jsdelivr.net/npm/jquery@3.6.4/dist/jquery.min.js');
                    jqueryjs.setAttribute('type', 'text/javascript');
                    formPreviewWindow.document.head.appendChild(jqueryjs);
                    var parsleyjs = document.createElement('script');
                    parsleyjs.setAttribute('src', 'https://cdn.jsdelivr.net/npm/parsleyjs@2.9.2/dist/parsley.min.js');
                    parsleyjs.setAttribute('type', 'text/javascript');
                    formPreviewWindow.document.head.appendChild(parsleyjs);
                }

                document.getElementById('getXML').addEventListener('click', function () {
                    alert(formBuilder.actions.getData('xml'));
                });
                document.getElementById('getJSON').addEventListener('click', function () {
                    alert(formBuilder.actions.getData('json'));
                });
                document.getElementById('getJS').addEventListener('click', function () {
                    alert('check console');
                    console.log(formBuilder.actions.getData());
                });
            }

        });
    });

});